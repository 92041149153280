.emptyStateContainer {
  margin: 4rem auto;
  padding: 2rem;
  position: relative;
  background-color: var(--secondary);
  border-radius: 10px;
  box-shadow: 4px 4px 20px #00000050;
  display: flex;
  flex-direction: column;
}

.emptyStateContainer p { margin-bottom: 2.5rem; }

.emptyStateContainer h1, .emptyStateContainer p { color: var(--onSecondaryLow); }