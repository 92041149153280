.logoSmall {
  width: 70px;
}

.logoLarge {
  width: 144px;
}

.teamLogo {
  height: 52px;
}
