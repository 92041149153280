.timeContainer {
  width: 100%;
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.time {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label, .timeValue, .date, .field {
  text-align: center;
  font-weight: var(--boldWeight);
  text-transform: uppercase;
}

.label {
  font-size: .75rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: var(--regularWeight);
  letter-spacing: var(--allcaps-letter-spacing);;
}

.timeValue {
  font-size: 1.5rem;
  margin: 2px .25em .25em;
}

.date {
  font-size: .875rem;
  margin: 0;
}

.field {
  font-size: 1.125rem;
  margin: .1em;
}