.supportSectionContainer {
  display: grid;
  flex-direction: column;
  gap: 1rem;
}

.supportSectionContainer div {
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  gap: 1rem;
}

.supportSectionContainer div p {
  margin: 0;
  text-align: left;
}

.strokedIcon path {
  stroke: var(--onSecondaryLow);
}

.filledIcon path {
  fill: var(--onSecondaryLow);
}