.ticketContainer {
  padding: 1.5rem;
  background-color: var(--secondary);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: 600px;
  box-shadow: 4px 4px 20px #00000050;
}

.ticketContainer > * {
  margin-bottom: .75rem;
}

.ticketContainer > *:last-child {
  margin-bottom: 0;
}

.ticketContainer p { 
  margin: 0;
  font-size: .75rem; 
  font-weight: var(--boldWeight);
  text-transform: uppercase;
  color: var(--onSecondaryLow);
  text-align: left;
}

.ticketContainer .publicDescription {
  text-transform: none;
  font-size: 1.25rem;
}

.ticketContainer .time { font-size: .625rem }

.ticketContainer .parkingInfo { 
  color: var(--onSecondaryHigh);
}

.ticketContainer .parkingInfo:first-child {
  margin-bottom: .25rem;
}

.ticketContainer a {
  margin: 1rem 0 0 0;
  padding: .75rem;
  flex: 1;
}

.mainRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mainRow > *:first-child {
  margin-right: 2rem;
}

.mainRow > div > :first-child {
  margin-bottom: .25rem;
}

.bordered {
  border: 1px solid var(--onSecondaryHigh);
  box-shadow: 4px 4px 20px #00000079;
}